import React, { useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../components/Checkbox";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [address, setAddress] = useState("");
  const [additionalAddress, setAdditionalAddress] = useState("");
  const [contact, setContact] = useState("");

  const [isAgreeTerms,setAgreeTerms] = useState(false)
  const [isAgreeReceiveAdv,setAgreeReceiveAdv] = useState(false)
  return (
    <div className="register">
      <div className="register-container">
        <div className="register-container-title">
          <span>Join us</span>
        </div>
        <div className="line"></div>
        <div className="userName">
          <Input
            required
            title="아이디(이메일)"
            value={email}
            placeholder="example@email.com"
            onChange={(e) => {
              setEmail(e);
            }}
          />
        </div>

        <div className="inputItem">
          <Input
            required
            title="비밀번호"
            type="password"
            value={password}
            placeholder="영문, 숫자, 특수문자를 포함한 8~12자리"
            onChange={(e) => {
              setPassword(e);
            }}
          />
        </div>
        <div className="inputItem">
          <Input
            required
            title="비밀번호 확인"
            type="password"
            value={verifyPassword}
            onChange={(e) => {
              setVerifyPassword(e);
            }}
          />
        </div>

        <div className="inputItem">
          <Input
            title="주소"
            value={address}
            onChange={(e) => {
              setAddress(e);
            }}
            rightAction={
              <p onClick={() => {}} className="searchAction">
                검색
              </p>
            }
          />
        </div>

        <div className="inputItem">
          <Input
            value={additionalAddress}
            onChange={(e) => {
              setAdditionalAddress(e);
            }}
            placeholder="추가 주소"
          />
        </div>

        <div className="inputItem">
          <Input
            title="연락처"
            value={contact}
            onChange={(e) => {
              setContact(e);
            }}
          />
        </div>

        <div className="line"></div>
        <div className="checkbox">
            <div className="checkboxItem">
                <Checkbox checked={isAgreeTerms} onChange={(e) => setAgreeTerms(e)} />
                <div className="content">
                    <a href="https://goole.com">이용약관</a> 및 <a href="https://goole.com">개인정보 처리 방침</a> 에 동의합니다. (필수)
                </div>
            </div>
            <div className="checkboxItem">
                <Checkbox checked={isAgreeReceiveAdv} onChange={(e) => setAgreeReceiveAdv(e)} />
                <div className="content">
                광고 및 메일 수신에 동의합니다. (선택)
                </div>
            </div>

        </div>
        <Button onClick={() => {}} text="가입하기" />
      </div>
    </div>
  );
}

export default Register;
