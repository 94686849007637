import { Breadcrumb } from "antd";
import React from "react";
import arrowRight from "../../../Assets/Images/13-px-r-arrow@3x.png";
import Line from "../../../components/Line";

import icon1 from "../../../Assets/Images/order-icon@3x.png";
import icon2 from "../../../Assets/Images/profile-icon@3x.png";
import icon3 from "../../../Assets/Images/heart-icon@3x.png";
import icon4 from "../../../Assets/Images/coupon-icon@3x.png";
import icon5 from "../../../Assets/Images/board-icon@3x.png";
import icon6 from "../../../Assets/Images/truck-icon@3x.png";

import "./styles.scss";
function MyAccount() {
  return (
    <div className="myAccount">
      <div className="myAccount-container">
        <div className="title">Shopping Bag</div>
        <div className="breadcrumb">
          <Breadcrumb
            separator=">"
            items={[
              {
                title: <span>01 home</span>,
              },
              {
                title: <p>02 my account</p>,
              },
            ]}
          />
        </div>

        <div className="history">
          <div className="history-title">
            <div className="tit">최근 주문</div>
            <div className="more">
              <p>최근 주문</p>
              <img src={arrowRight} alt="" />
            </div>
          </div>

          <div className="history-category">
            <Line type="solid" />
            <div className="history-category-detail">
              <p>주문일</p>
              <p>주문내역</p>
              <p>주문번호</p>
              <p>결제금액</p>
            </div>
            <Line type="solid" />
          </div>

          <div className="history-detail"></div>
          <div className="history-empty">
            <p>최근 주문내역이 없습니다.</p>
          </div>

          <div className="account-info">
            <Line type="solid" />
            <div className="account-info-detail">
              <div className="item-left">
                <AccountItemInfo tit="- 사용가능적립금" detail="1,000원" />
                <AccountItemInfo tit="- 사용가능적립금" detail="0원" />
                <AccountItemInfo tit="- 쿠폰" detail="0원" />
              </div>
              <div className="divide"></div>
              <div className="item-right">
                <AccountItemInfo tit="- 사용가능적립금" detail="1,000원" />
                <AccountItemInfo tit="- 총주문" detail="0원" />
              </div>
            </div>
            <Line type="solid" />
          </div>
        </div>

        <div className="myOrder">
          <div className="myOrder-title">
            <div className="tit">나의 주문처리 현황</div>
          </div>

          <div className="myOrder-category">
            <Line type="solid" />
            <div className="myOrder-category-detail">
              <div className="myOrder-item">
                <div className="tit">입금전</div>
                <div className="amount">0</div>
              </div>
              <div className="myOrder-item">
                <div className="tit">입금준비중</div>
                <div className="amount">0</div>
              </div>
              <div className="myOrder-item">
                <div className="tit">입금전</div>
                <div className="amount">0</div>
              </div>
              <div className="myOrder-item">
                <div className="tit">배송완료</div>
                <div className="amount">0</div>
              </div>
              <div className="myOrder-item">
                <div className="detail">- 취소 : 0</div>
                <div className="detail">- 교환 : 0</div>
                <div className="detail">- 반품 : 0</div>
              </div>
            </div>
            <Line type="solid" />
          </div>
        </div>

        <div className="myAccount-menus">
          {data.map((item, index) => (
            <AccountMenu
              key={index}
              img={item.img}
              tit={item.title}
              detail={item.detail}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MyAccount;

const AccountItemInfo = ({ tit, detail }: { tit: string; detail: string }) => (
  <div className="account-info-item">
    <div className="tit">{tit}</div>
    <div className="detail">{detail}</div>
  </div>
);
const data = [
  {
    img: icon1,
    title: "ORDER",
    detail: "주문내역",
  },
  {
    img: icon2,
    title: "PROFILE",
    detail: "회원정보",
  },
  {
    img: icon3,
    title: "wishlist",
    detail: "관심상품",
  },
  {
    img: icon4,
    title: "coupon",
    detail: "쿠폰",
  },
  {
    img: icon5,
    title: "board",
    detail: "게시물 관리",
  },
  {
    img: icon6,
    title: "address",
    detail: "배송 관리",
  },
];

const AccountMenu = ({
  img,
  tit,
  detail,
}: {
  img: any;
  tit: string;
  detail: string;
}) => (
  <div className="myAccount-menu">
    <div className="img">
      <img src={img} alt="" />
    </div>
    <div className="tit">{tit}</div>
    <div className="detail">{detail}</div>
  </div>
);
