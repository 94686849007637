import React from "react";
import './styles.scss'
interface Props {
  title: string;
  detail: string;
  color: string
  style?: React.CSSProperties;
}
function CommonArticle({ title, detail, style,color }: Props) {
  return (
    <div style={style} className="article">
      <h5 style={{color}}>{title}</h5>
      <p style={{color}}>{detail}</p>
    </div>
  );
}

export default CommonArticle;
