import React from "react";
import Line from "../../../../components/Line";
import { dataShop } from "../../../Home/Shop/Thumnail";
import likeIcon from "../../../../Assets/Images/hearticon.png";
import Pagination from "../../../../components/Pagination";
import "./styles.scss"
function InfoItem() {
  return (
    <div className="infoItem">
      <div className="infoItem-container">
        <div className="qna">
          <div className="tabs">
            <div className="tabs-title">상품 QNA</div>
            <div className="tabs-detail">
              {dataTabs.map((item, index) => (
                <div key={index} className={`tab ${item.active ? 'tabActive' : '' }`}>
                  {item.tit}
                </div>
              ))}
            </div>
          </div>
            <Line type="solid" />
          <div className="content-tabs">
            <span>상품에 관해 여러가지 질문해보세요.</span>
          </div>
        </div>

        <div className="bill-info">
          <div className="title">상품 결제 정보</div>
          <Line type="solid" />
          <div className="detail">
            <p>
              고액결제의 경우 안전을 위해 카드사에서 확인전화를 드릴 수도
              있습니다.{" "}
            </p>
            <p>
              확인과정에서 도난 카드의 사용이나 타인 명의의 주문등 정상적인
              주문이 아니라고 판단될 경우 임의로 주문을 보류 또는 취소할 수
              있습니다.{" "}
            </p>
            <p>
              무통장 입금은 상품 구매 대금은 PC뱅킹, 인터넷뱅킹, 텔레뱅킹 혹은
              가까운 은행에서 직접 입금하시면 됩니다.{" "}
            </p>
            <p>
              주문시 입력한 입금자명과 실제입금자의 성명이 반드시 일치하여야
              하며, 7일 이내로 입금을 하셔야 하며 입금되지 않은 주문은 자동취소
              됩니다.{" "}
            </p>
          </div>
        </div>

        <div className="exchange-info">
          <div className="title">교환 및 반품 정보</div>
          <Line type="solid" />
          <div className="content">
            <div className="content-tit">교환 및 반품이 가능한 경우</div>
            <div className="content-detail">
              <p>상품을 공급 받으신 날로부터 7일이내. </p>
              <p>공급받으신 상품 및 용역의 내용이 표시.광고 내용과</p>
              <p>다르거나 다르게 이행된 경우에는 공급받은 날로부터 7일이내.</p>
            </div>
          </div>
          <div className="content">
            <div className="content-tit">교환 및 반품이 불가능한 경우</div>
            <div className="content-detail">
              <p>
                고객님의 책임 있는 사유로 상품등이 멸실 또는 훼손된 경우. 단,
                상품의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외
              </p>
              <p>
                고객님의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히
                감소한 경우.
              </p>
              <p>
                시간의 경과에 의하여 재판매가 곤란할 정도로 상품등의 가치가
                현저히 감소한 경우
              </p>
              <p>복제가 가능한 상품등의 포장을 훼손한 경우</p>
              <p>
                (자세한 내용은 고객만족센터 1:1 E-MAIL상담을 이용해 주시기
                바랍니다.)
              </p>
            </div>
          </div>
        </div>

        <div className="best-info">
            <div className="title">관련 상품 BEST</div>
          <div className="items">
            {dataShop.slice(0, 5).map((item, index) => {
              return (
                <div onClick={() => {}} key={index} className="item">
                  <div className="img">
                    <img src={item.img} alt="#" />
                  </div>
                  <h5>{item.title}</h5>
                  <div className="detail">
                    <p>{item.price}</p>
                    <div className="like">
                      <img src={likeIcon} alt="" /> <span>{item.like}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Pagination />
        </div>
      </div>
    </div>
  );
}

export default InfoItem;
const dataTabs = [
  {
    tit: "상품 상세정보",
    active: false,
  },
  {
    tit: "상품 구매 안내",
    active: true,
  },
  {
    tit: "재입고 문의",
    active: false,
  },
  {
    tit: "배송문의",
    active: false,
  },
  {
    tit: "기타",
    active: false,
  },
  {
    tit: "상품 Q&A",
    active: true,
    textUnderline: true,
  },
];
