import React from "react";
import Icon1 from "../../../Assets/Images/producticon-01.png";
import Icon2 from "../../../Assets/Images/producticon-02.png";
import Icon3 from "../../../Assets/Images/producticon-03.png";
import Icon4 from "../../../Assets/Images/go-icon.png";
import Icon5 from "../../../Assets/Images/best-image-01@3x.png";
import Icon6 from "../../../Assets/Images/best-image-02.png";
import Icon7 from "../../../Assets/Images/best-image-03.png";
import "./styles.scss";
import CommonArticle from "../../../components/CommonArticle";

function Product() {
  const data = [
    { bg: Icon1, thumb: Icon5, title: "How to create mobile-optimized" },
    { bg: Icon2, thumb: Icon6, title: "How to create mobile-optimized" },
    { bg: Icon3, thumb: Icon7, title: "How to create mobile-optimized" },
  ];
  return (
    <section className="product">
      <CommonArticle
        title="Best Product"
        color="#f96400"
        detail="How to create mobile-optimized videos in minutes. Not a designer, 
                        every team makes a lot of videos Can be trimmed. Take the first"
      />
      <div className="product__list">
        {data.map((item, index) => (
          <div key={index} className="product__list__items">
            <img src={item.thumb} alt="" />
            <div className="product__list__items--img">
              <img src={item.bg} alt="" />
            </div>
            <div className="product__list__items--button">
              <p>{item.title}</p>
              <img src={Icon4} alt="" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Product;
