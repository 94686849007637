import React from 'react'

import check from "../../Assets/Images/11-px-check-box@3x.png"
import nonCheck from "../../Assets/Images/11-px-non-cneck-box@3x.png"
import "./styles.scss"
interface Props {
    checked: boolean,
    onChange: (e:boolean) => void
    size?: 'normal' | 'large'
}
function Checkbox({checked,onChange,size = 'normal'}:Props) {
  return (
    <div className={`checkboxComponent ${size === 'large' ? 'checkboxLarge' : ''}`}>
        <img onClick={() => {
            onChange(!checked)
        }} src={checked ? check : nonCheck} alt="" />
    </div>
  )
}

export default Checkbox