import { Breadcrumb } from "antd";
import React, { useState } from "react";
import { Divider, Radio, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import img1 from "../../../Assets/Images/shop-thumbnail-01@3x.png";
import iconDelete from "../../../Assets/Images/19-px-x-box@3x.png";
import iconMinus from "../../../Assets/Images/minus-icon@3x.png";
import iconPlus from "../../../Assets/Images/plus-icon@3x.png";
import Button from "../../../components/Button";
import "./styles.scss"
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../components/Modules/routesString";
import { scrollToTop } from "../../../utils/scrollToTop";
interface DataType {
  key: React.Key;
  info: JSX.Element;
  amount: JSX.Element;
  value: JSX.Element;
  feeShip: JSX.Element;
}

function ShoppingBag() {
  const navigation = useNavigate()
  const handle = () => {
    scrollToTop()
    navigation(RoutesString.ShopBagOrder)
  }
  return (
    <div className="shoppingBag">
      <div className="shoppingBag-container">
        <div className="title">Shopping Bag</div>
        <div className="breadcrumb">
          <Breadcrumb
          separator=">"
            items={[
              {
                title: <p>01 Shopping Bag</p>,
              },
              {
                title: <span>02 order</span>,
              },
              {
                title: <span>order confirmed</span>,
              },
            ]}
          />
        </div>

        <div className="shoppingBag-table">
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          <div className="buttonDeleteItem">
            <Button style={{padding: '14px 30px'}} size="small" text="선택상품 삭제" type="outline" onClick={() => {}} />
          </div>

          <div className="detail-price">
            <div className="price">51,400 won</div>
            <span>+</span>
            <div className="price">3,000 won</div>
            <span>=</span>
            <div className="price">51,400 won</div>
          </div>

          <div className="buttonCheckout">
            <div className="button">
            <Button style={{padding: '14px 122px'}} text="check out" type="primary" onClick={handle} />
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingBag;

const columns: ColumnsType<DataType> = [
  {
    title: "상품 정보",
    dataIndex: "info",
    render: (item: JSX.Element) => <>{item}</>,
  },
  {
    title: "수량",
    dataIndex: "amount",
    render: (item: JSX.Element) => <>{item}</>,
  },
  {
    title: "주문금액",
    dataIndex: "value",
    render: (item: JSX.Element) => <>{item}</>,
  },
  {
    title: "배송비",
    dataIndex: "feeShip",
    render: (item: JSX.Element) => <>{item}</>,
  },
];

const InfoBag = () => {
  return (
    <div className="infoBag">
      <div className="bag">
        <img src={img1} alt="" />
        <div className="content">
          <div className="title">[Apparel] What happened beanie</div>
          <div className="price">23,000원</div>
          <div className="discount">[10%] 2,300원</div>
        </div>
      </div>
      <div className="button-delete">
        <img src={iconDelete} alt="" />
      </div>
      <div className="line"></div>
    </div>
  );
};

const AmountBag = () => {
  const [value, setValue] = useState(1);
  return (
    <div className="amountBag">
      <div className="img-minus">
        <img src={iconMinus} alt="" />
      </div>
      <span>{value}</span>
      <div className="img-minus">
        <img src={iconPlus} alt="" />
      </div>
    </div>
  );
};

const ValueBag = () => {
  return (
    <div className="valueBag">
      <div className="value">20,700 won</div>
     <div className="valueBag-button">
      <span>BUY NOW</span>
     </div>
    </div>
  );
};

const ShipBag = () => {
  return (
    <div className="shipBag">
      <div className="value">3,000 원</div>
    </div>
  );
};

const data: DataType[] = [
  {
    key: "1",
    info: <InfoBag />,
    amount: <AmountBag />,
    value: <ValueBag />,
    feeShip: <ShipBag />,
  },
  {
    key: "2",
    info: <InfoBag />,
    amount: <AmountBag />,
    value: <ValueBag />,
    feeShip: <ShipBag />,
  },
];

// rowSelection object indicates the need for row selection
const rowSelection = {
  // onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
  //   console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  // },
  // getCheckboxProps: (record: DataType) => ({
  //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
  //   name: record.name,
  // }),
};
