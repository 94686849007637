import { useState } from "react";
import { Select, Space } from 'antd';

import "./styles.scss";

interface Props {
  value: string,
  label:string
}
const Dropdown = ({ data,style,defaultValue,showArrow }: { data:Props[],style?:React.CSSProperties,defaultValue?:string,showArrow?:boolean  }) => {


  return (
    <Select
    defaultValue={defaultValue ? defaultValue : "인기순"} 
    showArrow={showArrow ? showArrow : false}
    style={{ width: 125,...style}}
    options={data}
    suffixIcon
  />
  );
};
export default Dropdown;
