import Router from './router/Router';
import "./App.scss"
function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
