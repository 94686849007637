export const RoutesString = {
  PageNotFound: '*',
  Home: `/`,
  Login: '/login',
  Shop: '/shop',
  ShopDetail: '/shop/:id',
  Register: '/register',
  ShoppingBag: '/order/shoppingBag',
  ShopBagOrder: '/order/shopBagOrder',
  MyAccount: '/myAccount',
  Introduce: '/introduce',
  Privacy: '/privacy',
};
