import { Breadcrumb } from "antd";
import Line from "../../../components/Line";

import Form from "./Form";
import OrderInfo from "./OrderInfo";
import "./styles.scss";

function ShopBagOrder() {
  

  return (
    <div className="shopOrder">
      <div className="shopOrder-container">
        <div className="title">ORDER</div>

        <div className="order-container">

          <div className="order-form">
            <div className="breadcrumb">
              <Breadcrumb
                separator=">"
                items={[
                  {
                    title: <span>01 Shopping Bag</span>,
                  },
                  {
                    title: <p>02 order</p>,
                  },
                  {
                    title: <span>order confirmed</span>,
                  },
                ]}
              />
            </div>

            <Line type="solid" />
            <div className="title">주문자 정보</div>
            <Line type="solid" />
            <div className="address">김형준 +82)10 - 0000-0000</div>
            <div className="verify">본인인증 완료</div>

            <Form />
          </div>

          <OrderInfo />
        </div>
      </div>
    </div>
  );
}

export default ShopBagOrder;


