import React from "react";
import "./styles.scss"
interface Props {
  placeholder?: string;
  value1: string;
  value2: string;
  value3: string;
  onChange: (value:string) => void;
  rightAction?: JSX.Element;
  title?: string;
  required?: boolean;
  type?: 'password' | 'none',
  row?:boolean,
  style?:any
}
function GroupInput({
  placeholder,
  value1,
  value2,
  value3,
  onChange,
  rightAction,
  title,
  required,
  type,
  row,
  style
}: Props) {
  const onChangeText = (e:any) => {
    onChange(e.target.value)
  }
  return (
    <div style={style} className={`inputComponent ${row ? 'rowInput' : ''}`}>
      <div className="input-title">
        {title}
        <span>{required && "*"}</span>
      </div>
      <div className="groupInput">
      <div className="inputMain groupInputItem">
        <input
          type={type === 'password' ? 'password': 'text'}
          value={value1}
          onChange={onChangeText}
          placeholder={placeholder}
        />
         
      </div>
        <span className="divide"></span>
        <div className="inputMain groupInputItem">
        <input
          type={type === 'password' ? 'password': 'text'}
          value={value2}
          onChange={onChangeText}
          placeholder={placeholder}
        />
         
      </div>

      <span className="divide"></span>
        <div className="inputMain groupInputItem">
        <input
          type={type === 'password' ? 'password': 'text'}
          value={value3}
          onChange={onChangeText}
          placeholder={placeholder}
        />
         
      </div>
      </div>
     

    </div>
   
  );
}

export default GroupInput;
