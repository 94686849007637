import React from "react";
import "./styles.scss"
interface Props {
  placeholder?: string;
  value: string;
  onChange: (value:string) => void;
  rightAction?: JSX.Element;
  title?: string;
  required?: boolean;
  type?: 'password' | 'none',
  row?:boolean,
  style?:any
}
function Input({
  placeholder,
  value,
  onChange,
  rightAction,
  title,
  required,
  type,
  row,
  style
}: Props) {
  const onChangeText = (e:any) => {
    onChange(e.target.value)
  }
  return (
    <div style={style} className={`inputComponent ${row ? 'rowInput' : ''} ${rightAction ? 'rightActionInput' : ''}`}>
      <div className="input-title">
        {title}
        <span>{required && "*"}</span>
      </div>
      <div className="inputMain">
        <input
          type={type === 'password' ? 'password': 'text'}
          value={value}
          onChange={onChangeText}
          placeholder={placeholder}
        />
         
      </div>
      {rightAction && rightAction}
    </div>
   
  );
}

export default Input;
