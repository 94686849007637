import React from "react";
import banner from "../../Assets/Images/bannerimg@3x.png";
import bannerSecond from "../../Assets/Images/img@3x.png";
import "./styles.scss";
function Introduce() {
  return (
    <main>
      <div className="introduce">
        <div className="introduce-banner">
          <img src={banner} alt="" />
          <div className="content">
            <div className="title">What Happened!</div>
            <div className="detail">
              How to create mobile-optimized videos in minutes. Not a designer,{" "}
              <br />
              every team makes a lot of videos Can be trimmed. <br />
              Take the first step to your brand's success. <br />
              How to create mobile-optimized videos in minutes.
            </div>
          </div>
        </div>
        <div className="introduce-categories">
          {data.map((item, index) => (
            <div
              key={index}
              className={`introduce-category ${
                item.active ? "activeCategory" : ""
              }`}
            >
              <p>{item.name}</p>
            </div>
          ))}
        </div>

        <div className="introduce-container">
          <div className="introduce-misson">
            <div className="titleSection">what happnned to?</div>
            <div className="intro">
              ‘WHAT HAPPENED의 이야기는 우리들의 살아가는 <br />
              ’WHAT HAPPENED?’ 라는 마음으로 <br />
              ‘무엇’을 모티브로 하여 다양한작업을 하고 있습니다.
              <br />
              일상속의 작은 물건들이 ~할 수 있으며 있으며,일상속의 작은 물건들이
              ~할 수 <br />
              일상속의 작은 물건들이 ~할 수 있으며 있으며,일상속의 작은 물건들이
              ~할 수 있으며
              <br />
              일상속의 작은 물건들이 ~할 수 있으며 있으며,중심으로
              <br />
              당신의 삶을 더 행복하게 만드는,
              <br />
              친절하고 다정하며 공감이 되는 디자인을 하는 것이 왓해픈의 방향성
              입니다.
            </div>

            <div className="content-detail">
              “what happened to” wants to make tour everyday, “oh happy day!”{" "}
              <br />
              we design daily itenms that can make you smile,
              <br />
              believing that small things can bring you big joy, Thank you, as
              always!
            </div>
          </div>

          <div className="introduce-contact">
            <div className="titleSection">contact us</div>
            <div className="content-detail">
              <p>
                서울특별시 강남구 도산대로 8길 17 2층 <br />
                2nd floor, 17, Dosan-daero 8-gil, Gangnam-gu, Seoul <br />
                +82 2 000 0000
                <br />
                wht30@gmail.com
              </p>
            </div>
          </div>

          <div className="introduce-partner">
            <div className="titleSection">partnership</div>
            <div className="content-detail">
              <p>
                서울특별시 강남구 도산대로 8길 17 2층 <br />
                2nd floor, 17, Dosan-daero 8-gil, Gangnam-gu, Seoul <br />
                +82 2 000 0000 <br />
                wht30@gmail.com
              </p>
            </div>
          </div>

          <div className="introduce-container-banner">
            <img src={bannerSecond} alt="" />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Introduce;
const data = [
  {
    name: "about",
    active: true,
    router: "",
  },
  {
    name: "contact us",
    router: "",
  },
  {
    name: "Campaign",
    router: "",
  },
  {
    name: "story",
    router: "",
  },
  {
    name: "Toon",
    router: "",
  },
];
