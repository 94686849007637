import React from "react";
import "./styles.scss"

interface Props {
  text: string;
  onClick?: () => void;
  style?:any
}
function Button({ text, onClick,style }: Props) {
  return (
    <div
      onClick={onClick}
      style={style}
      className={`homeButton`}
    >
      <p>{text}</p>
    </div>
  );
}

export default Button;