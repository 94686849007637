

import { RoutesString } from '../components/Modules/routesString';
import PageErr from '../pages/404/PageErr';
import Home from '../pages/Home';
import MyAccount from '../pages/Home/MyAccount';
import Introduce from '../pages/Introduce';
import Login from '../pages/Login';
import ShopBagOrder from '../pages/Order/ShopBagOrder';
import ShoppingBag from '../pages/Order/ShoppingBag';
import Privacy from '../pages/Privacy';
import Register from '../pages/Register';
import Shop from '../pages/Shop';
import ShopDetails from '../pages/Shop/ShopDetails';


const pages = [
  {
    path: RoutesString.PageNotFound,
    component: PageErr,
    exact: true,
  },

  /* Home */
  {
    path: RoutesString.Home,
    component: Home,
    exact: true,
  },
  {
    path: RoutesString.Login,
    component: Login,
    exact: true,
  },
  {
    path: RoutesString.Shop,
    component: Shop,
    exact: true,
  },
  {
    path: RoutesString.ShopDetail,
    component: ShopDetails,
    exact: true,
  },
  {
    path: RoutesString.Register,
    component: Register,
    exact: true,
  },
  {
    path: RoutesString.ShoppingBag,
    component: ShoppingBag,
    exact: true,
  },
  {
    path: RoutesString.ShopBagOrder,
    component: ShopBagOrder,
    exact: true,
  },
  {
    path: RoutesString.MyAccount,
    component: MyAccount,
    exact: true,
  },
  {
    path: RoutesString.Introduce,
    component: Introduce,
    exact: true,
  },
  {
    path: RoutesString.Privacy,
    component: Privacy,
    exact: true,
  }
];

export default pages;
