import React from "react";
import "./styles.scss";
import banner from "../../../Assets/Images/main-banner.png";
function Banner() {
  return (
    <section className="home__banner">
      <img src={banner} alt="" />
    </section>
  );
}

export default Banner;
