import React from 'react'
import ItemDetail from './ItemDetail'
import InfoItem from './InfoItem'

function ShopDetails() {
  return (
    <main>
        <ItemDetail />
        <InfoItem />
    </main>
  )
}

export default ShopDetails