import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import IconLogo from "../../Assets/Images/logo@3x.png";

import "./styles.scss";
import { useState } from "react";
import { MdCancel } from "react-icons/md";
import { RoutesString } from "../Modules/routesString";
import { scrollToTop } from "../../utils/scrollToTop";

export default function Header() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
    <header className="header">
      <nav className="navbar">
        <div className="navbar__logo">
          <Link onClick={() => scrollToTop()} to="/">
            <img src={IconLogo} alt="" />
          </Link>
        </div>
        <div onClick={() => setOpenMenu(!openMenu)} className="navbar_icon">
          <FaBars />
        </div>
        <div className="navbar__menu">
          <ul className="navbar__menu__items">
            {data.map((item, index) => (
              <Link onClick={() => scrollToTop()} key={index} to={item.router}>
                <li
                  className={`${
                    !item.authItem
                      ? "navbar__menu__items--li"
                      : "navbar__menu__auth--li"
                  }`}
                >
                  {item.name}{" "}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </nav>
    </header>
    
    <aside className={openMenu ? "sidebar" : "sidebar__hidden"}>
      <div className="sidebar__icon">
        <MdCancel onClick={()=>setOpenMenu(false)} />
      </div>
      <div className="sidebar__menu">
        <ul className="sidebar__menu__items">
          {data.map((item, index) => (
            <Link onClick={() => scrollToTop()} key={index} to={item.router}>
              <li className="sidebar__menu__items--li">{item.name}</li>
            </Link>
          ))}
        </ul>
      </div>
    </aside>
    </>
  );
}

const data = [
  { name: "Introduction", router: "/shop" },
  { name: "solution", router: "/" },
  { name: "Rate plan", router: "/" },
  { name: "login", router: RoutesString.Login, authItem: true },
  { name: "Apply for free use ", router: "", authItem: true },
];
