import React from "react";
import Icon2 from "../../../Assets/Images/hearticon.png";
import "./styles.scss";
import img1 from "../../../Assets/Images/img-01.png";
import img2 from "../../../Assets/Images/img-02.png";
import img3 from "../../../Assets/Images/img-03.png";
import img4 from "../../../Assets/Images/img-04.png";
import img5 from "../../../Assets/Images/img-05.png";
import img6 from "../../../Assets/Images/img-06.png";
import img7 from "../../../Assets/Images/img-07.png";
import img8 from "../../../Assets/Images/img-08.png";
import img9 from "../../../Assets/Images/img-09.png";
import img10 from "../../../Assets/Images/img-10.png";
import img11 from "../../../Assets/Images/img-11.png";
import img12 from "../../../Assets/Images/img-12.png";
import img13 from "../../../Assets/Images/img-13.png";
import img14 from "../../../Assets/Images/img-14.png";
import img15 from "../../../Assets/Images/img-15.png";
import img16 from "../../../Assets/Images/img-16.png";
import img17 from "../../../Assets/Images/img-17.png";
import img18 from "../../../Assets/Images/img-18.png";
import img19 from "../../../Assets/Images/img-19.png";
import img20 from "../../../Assets/Images/img-20.png";
import img21 from "../../../Assets/Images/img-21.png";
import img22 from "../../../Assets/Images/img-22.png";
import img23 from "../../../Assets/Images/img-23.png";
import img24 from "../../../Assets/Images/img-24.png";
import img25 from "../../../Assets/Images/img-25.png";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../utils/scrollToTop";
import { RoutesString } from "../../../components/Modules/routesString";
import Button from "../components/Button";
function HomeShop() {
  const navigation = useNavigate()
  const handleItem = () => {
    scrollToTop()
    navigation(RoutesString.Shop)
  }
  return (
    <>
      <section className="homeShop">
        {dataShop.map((item, index) => {
          return (
            <div onClick={handleItem} key={index} className="homeShop__items">
              <div className="img">
              <img src={item.img} alt="#" />
              </div>
              <h5>{item.title}</h5>
              <div className="homeShop__items__price">
                <p>{item.price}</p>
                <div className="homeShop__items__price--heart">
                  <img src={Icon2} alt="" /> <span>{item.like}</span>
                </div>
              </div>
            </div>
          );
        })}
      </section>
      <div className="homeShop__button">
      <Button style={{}} text="SEE MORE" />
      </div>
    </>
  );
}

export default HomeShop;

export const dataShop = [
    { img: img1,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-1' },
    { img: img2,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-2' },
    { img: img3,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-3' },
    { img: img4,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-4'},
    { img: img5,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-5'},
    { img: img6, title: '[what happen] How to create',price: "2,500 won", like: 236,id:'item-6' },
    { img: img7,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-7' },
    { img: img8,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-8'},
    { img: img9,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-9'},
    { img: img10,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-1' },
    { img: img11,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-2' },
    { img: img12,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-3' },
    { img: img13,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item4'},
    { img: img14,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-5'},
    { img: img15,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-6'},
    { img: img16,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-7'},
    { img: img17,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-8'},
    { img: img18,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-9'},
    { img: img19,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-1' },
    { img: img20,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-2' },
    { img: img21,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-3'},
    { img: img22,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-4'},
    { img: img23,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-5'},
    { img: img24,title: '[what happen] How to create', price: "2,500 won", like: 236,id:'item-6' },
    { img: img25,title: '[what happen] How to create', price: "2,500 won", like: 236 ,id:'item-7'},
];
