import React, { useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import googleIcon from "../../Assets/Images/google-icon@3x.png";
import naverIcon from "../../Assets/Images/naver-icon@3x.png";
import kakaoIcon from "../../Assets/Images/kakao-icon@3x.png";
import facebookIcon from "../../Assets/Images/facebook-icon@3x.png";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../components/Modules/routesString";
import { scrollToTop } from "../../utils/scrollToTop";

function Login() {
  const navigation = useNavigate()
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const handle = () => {
    scrollToTop()
    navigation(RoutesString.Register)
  }
  return (
    <div className="login">
      <div className="login-container">
        <div className="login-container-title">
          <span>Welcome!</span>
        </div>
        <div className="line"></div>
        <div className="userName">
          <Input
            value={userName}
            placeholder="아이디 (이메일)"
            onChange={(e) => {
              setUserName(e);
            }}
          />
        </div>

        <div className="password">
          <Input
            type="password"
            value={password}
            placeholder="비밀번호"
            onChange={(e) => {
              setPassword(e);
            }}
          />
        </div>
        <Button onClick={() => {}} text="로그인하기" />

        <div className="social">
          <div className="title">SNS 계정으로 로그인</div>
          <div className="social-list">
            {dataSocial.map((item, index) => (
              <a href={item.router} key={index} className="socialItem">
                <img src={item.img} alt="" />
              </a>
            ))}
          </div>
        </div>

        <Button
          text="계정이 없으신가요? 간편가입하기"
          type="outline"
          onClick={handle}
        />

        <div className="find">
          <div className="findId">
            <span>아이디(이메일)찾기</span>
          </div>

          <div className="findPassword">
            <span>비밀번호 찾기</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

const dataSocial = [
  {
    img: naverIcon,
    router: "",
  },
  {
    img: kakaoIcon,
    router: "",
  },
  {
    img: facebookIcon,
    router: "",
  },
  {
    img: googleIcon,
    router: "",
  },
];
