import { Route, Routes } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

import pages from "./pages";


const Router = () => {

  return (
    <>
      <Header />
      <Routes>
        {pages.map((item) => {
          return (
            <Route
              key={item.path}
              path={item.path}
              element={item.component()}
            />
          );
        })}
      </Routes>
      <Footer />
    </>
  );
};

export default Router;
