import React from "react";
import "./styles.scss"
interface Props {
  text: string;
  onClick: () => void;
  type?: "primary" | "outline" | 'outlineColor';
  disable?: boolean;
  size?: 'normal' | 'small'
  style?:any
}
function Button({ text, onClick, type = 'primary', disable,style }: Props) {
  return (
    <div
      onClick={onClick}
      style={style}
      className={`buttonComponent ${
        type === "primary" ? "primaryButton" : type === 'outline' ? "outlineButton" : 'outlineColor'
      } ${disable ? "disableButton" : ""}`}
    >
      <p>{text}</p>
    </div>
  );
}

export default Button;
