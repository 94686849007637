import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import iconHeart from "../../../../Assets/Images/24-px-greyline-heart@3x.png";
import imgSlide1 from "../../../../Assets/Images/item-img-01@3x.png";
import imgThumb1 from "../../../../Assets/Images/item-left-thumbnail-01@3x.png";
import imgThumb2 from "../../../../Assets/Images/item-left-thumbnail-02@3x.png";
import arrowRight from "../../../../Assets/Images/right-arrow@3x.png";
import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import Line from "../../../../components/Line";

// Import Swiper styles
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../../components/Modules/routesString";
import { scrollToTop } from "../../../../utils/scrollToTop";
import "./styles.scss";

function ItemDetail() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigation = useNavigate()
  const handle = () => {
    scrollToTop()
    navigation(RoutesString.ShoppingBag)
  }
  return (
    <div className="itemDetail">
      <div className="itemDetail-container">
        <div className="slideItem">
          <Swiper
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Thumbs]}
            className="mySwiperMain"
          >
            <SwiperSlide>
              <img alt="" src={imgSlide1} />
            </SwiperSlide>
            <SwiperSlide>
              <img alt="" src={imgSlide1} />
            </SwiperSlide>
          </Swiper>

          <Swiper
            onSwiper={(e) => setThumbsSwiper(e as any) as any}
            spaceBetween={0}
            slidesPerView={9}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiperThumb"
            direction="vertical"
          >
            <SwiperSlide>
              <img style={{ cursor: "pointer" }} alt="" src={imgThumb1} />
            </SwiperSlide>
            <SwiperSlide>
              <img style={{ cursor: "pointer" }} alt="" src={imgThumb2} />
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="detailItem">
          <Line type="solid" />

          <div className="detailItem-header">
            <div className="detailItem-header-content">
              <div className="title">[Apparel] what happened beanie</div>
              <div className="price">16,000 won</div>
            </div>
            <div className="wishIcon">
              <img src={iconHeart} alt="" />
              <span>wish</span>
            </div>
          </div>
          <Line type="light" />

          <div className="infoItems">
            <div className="infoItems-title">상품 정보</div>
            <div className="infoItems-detail">
              <InfoItem title="재질" detail="아크릴 100%" />
              <InfoItem
                title="이즈세"
                detail="16.5cm x 18.5cm / 펼쳤을 때 26cm"
              />
              <InfoItem
                title="세척 방법"
                detail={`30도 이하의 미지근한 물에서 중성세제로 단독손세탁을 권장 \n 그늘진 곳에 뉘어서 건조 \n정전기 예방을 위해 섬유유연제로 헹궈주시면 더 좋음`}
              />
            </div>
            <div className="infoItems-ads">
              <div className="content">
                지금 왓헤픈 회원가입시 <span>3% 할인권</span> 증정
              </div>
              <div className="arrow">
                <img src={arrowRight} alt="" />
              </div>
            </div>
          </div>
          <Line type="light" />
          <div className="infoShip">
            <div className="infoShip-title">배송 정보</div>
            <div className="infoShip-detail">
              <InfoItem
                title="배송비"
                detail={`50,000원 이상 구매시 무료배송 (미만시 배송비 2500원 발생) 제주도 \n 를 포함한 도서/산간지역 추가 배송비 없음`}
              />
              <InfoItem
                title="배송예정"
                subDetail="04/20(화)"
                detail="이내 도착 예정 97%"
              />
            </div>
          </div>

          <Line type="light" />

          <div className="select-colors">
            <Dropdown
              defaultValue="COLOR: SIZE"
              showArrow
              style={{ width: "100%" }}
              data={dataSelect}
            />
          </div>

          <div className="buttons">
            <Button text="add cart" onClick={() => {}} type="outlineColor" />
            <Button text="buy now" onClick={handle} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetail;

const InfoItem = ({
  title,
  detail,
  subDetail,
}: {
  title: string;
  detail: string;
  subDetail?: string;
}) => {
  return (
    <div className="infoItem">
      <div className="title">{title}</div>
      <div className="detail">
        {subDetail && <span>{subDetail}</span>}
        {detail}
      </div>
    </div>
  );
};

const dataSelect = [
  { value: "Color: size", label: "Color: size" },
  { value: "Color: size", label: "Color: size" },
  { value: "Color: size", label: "Color: size" },
  { value: "Color: size", label: "Color: size" },
];
