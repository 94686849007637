import React, { useState } from "react";
import Checkbox from "../../../components/Checkbox";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import GroupInput from "../../../components/Input/GroupInput";
import Line from "../../../components/Line";

function Form() {
  const [isCheckForm, setCheckForm] = useState(false);
  return (
    <div className="form-shipping">
      <div className="form-shipping-title">
        <div className="title">배송정보</div>
        <div className="subTit">* 표시는 필수입력 항목</div>
      </div>
      <Line type="solid" />
      <div className="form">
        <div className="form-input">
          <Input
            row
            title="배송지 선택"
            type="none"
            value=""
            onChange={() => {}}
          />
        </div>
        <div className="form-input">
          <Input
            row
            title="수령인"
            required
            type="none"
            value=""
            onChange={() => {}}
          />
        </div>
        <div className="form-input">
          <Input
            row
            title="배송지"
            required
            type="none"
            value=""
            onChange={() => {}}
            rightAction={
              <div className="rightAction-input">
                <p>우편번호 검색</p>
              </div>
            }
          />
        </div>
        <div className="form-input">
          <Input
            row
            title=""
            type="none"
            placeholder="상세주소 입력"
            value=""
            onChange={() => {}}
          />
        </div>

        <div className="form-input">
          <GroupInput
            row
            title="연락처1"
            required
            type="none"
            value1=""
            value2=""
            value3=""
            onChange={() => {}}
          />
        </div>
        <div className="form-input">
          <GroupInput
            row
            title="연락처2"
            required
            type="none"
            value1=""
            value2=""
            value3=""
            onChange={() => {}}
          />
        </div>
        <div className="form-input">
          <div className="form-input-checkbox">
            <div className="tit"></div>
            <div className="checkBox">
              <Checkbox
               size='large'
                checked={isCheckForm}
                onChange={() => {
                  setCheckForm(!isCheckForm);
                }}
              />
              <div className="detail">배송지를 배송지 목록에 추가합니다.</div>
            </div>
          </div>
        </div>
        <div className="form-input">
          <Input
            row
            title=""
            type="none"
            placeholder=""
            value="배송시 요청사항을 선택해 주세요."
            onChange={() => {}}
          />
        </div>
      </div>

      <div className="form-shipping-title">
        <div className="title">쿠폰 / 마일리지</div>
      </div>
      <Line type="solid" />
      <div className="form">
        <div className="form-input">
          <Input
            row
            title="쿠폰"
            placeholder="사용가능 쿠폰 1장 / 보유 2장"
            type="none"
            value=""
            onChange={() => {}}
          />
        </div>
        <div className="form-input">
          <Input
            row
            title="마일리지"
            placeholder="0원"
            type="none"
            value=""
            onChange={() => {}}
          />
        </div>
      </div>

      <div className="form-shipping-title">
        <div className="title">결제방법</div>
      </div>
      <Line type="solid" />
      <div className="buttons">
        {data.map((item, index) => (
          <div
            key={index}
            className={`button ${item.active ? "buttonActive" : ""}`}
          >
            <p>{item.tit}</p>
          </div>
        ))}
      </div>

      <div className="dropdown-item">
        <Dropdown
          defaultValue="카드사를 선택해주세요."
          data={dataSelect}
          style={{ width: "100%" }}
        />
      </div>

      <div className="box-buttons">
        {dataButtons.map((item, index) => (
          <div key={index} className="box-button">
            <div className="buttonText">{item.titButton}</div>
            <div className="detail">{item.detail}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Form;

const data = [
  {
    tit: "신용/체크카드",
    active: true,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
  {
    tit: "신용/체크카드",
    active: false,
  },
];
const dataSelect = [
  { value: "인기순", label: "인기순" },
  { value: "인기순", label: "인기순" },
  { value: "인기순", label: "인기순" },
  { value: "인기순", label: "인기순" },
];
const dataButtons = [
  {
    titButton: "공지",
    detail: "우리페이 앱 종료에 따른 결제 안내",
  },
  {
    titButton: "공지",
    detail: "품절 취소시 환불 안내",
  },
  {
    titButton: "혜택",
    detail: "현대카드 2% 청구할인, 20 % m포인트 사용",
  },
  {
    titButton: "혜택",
    detail: "신용카드 무이자 할부 안내",
  },
];
