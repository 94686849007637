import React from 'react'
import Banner from './Banner/Banner'
import Discover from './Discover/Discover'
import Intro from './Intro/Intro'
import HappenIssue from './Issue/Issue'
import Product from './Product/Product'
import Story from './Story/Story'
import HomeShop from './Shop/Thumnail'

function Home() {
  return (
    <main>
    <Banner />
    <Intro />
    <Product />
    <HomeShop />
    <Story />
    <HappenIssue />
    <Discover />
</main>
  )
}

export default Home