import React, { useState } from "react";
import Line from "../../../components/Line";
import img1 from "../../../Assets/Images/oredr-img-1@3x.png";
import img2 from "../../../Assets/Images/order-img-2@3x.png";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
function OrderInfo() {
  const [isCheck, setCheck] = useState(false);
  return (
    <div className="order-info">
      <div className="order-title">주문정보</div>
      <Line type="solid" />
      <div className="" style={{ paddingTop: 20 }}></div>

      <div className="order-item">
        <div className="order-img">
          <img src={img1} alt="" />
        </div>
        <div className="order-detail">
          <div className="tit">
            WHP <br /> [apparel] what happened beanie
          </div>
          <div className="price">23,000원</div>
          <div className="discount">[10%] 2,300원</div>
        </div>
      </div>

      <div className="order-item">
        <div className="order-img">
          <img src={img2} alt="" />
        </div>
        <div className="order-detail">
          <div className="tit">
            WHP <br /> [apparel] what happened beanie
          </div>
          <div className="price">23,000원</div>
          <div className="discount">[10%] 2,300원</div>
        </div>
      </div>
      <div className="" style={{ paddingTop: 30 }}></div>
      <Line type="solid" />

      <div className="order-details">
        {orderDetail.map((item, index) => (
          <div key={index} className="order-detail">
            <div className="tit">{item.tit}</div>
            <div className="detail">{item.detail}</div>
          </div>
        ))}
        <div className="sum-order">
          <div className="tit">총 결제금액</div>
          <div className="detail">54,400 원</div>
        </div>
      </div>
      <Line type="solid" />
      <div className="checkBox">
        <Checkbox
        size='large'
          checked={isCheck}
          onChange={() => {
            setCheck(!isCheck);
          }}
        />
        <div className="detail">
          상품 및 구매 조건을 확인하였으며, 결제 대행 서비스에 동의합니다.
          (필수)
        </div>
      </div>

      <div className="more">
        개인정보 처리 방침 & 이용약관 <br />
        결제대행 서비스 이용약관 (주)KG이니시스, (주)토스페이먼츠
      </div>
      <div className="button">
        <Button
          onClick={() => {}}
          size="normal"
          type="primary"
          text="CHECK OUT"
        />
      </div>
    </div>
  );
}

export default OrderInfo;

const orderDetail = [
  {
    tit: "총 상품금액",
    detail: "51,400원",
  },
  {
    tit: "쿠폰 사용",
    detail: "- 0 원",
  },
  {
    tit: "마일리지 사용",
    detail: "- 0 원",
  },
  {
    tit: "배송비",
    detail: "3,000 원",
  },
];
