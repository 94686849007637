import React, { useState } from "react";
import banner from "../../Assets/Images/banner-img@3x.png";
import viewMode1 from "../../Assets/Images/orange-box@3x.png";
import viewMode2 from "../../Assets/Images/grey-box@3x.png";
import searchButton from "../../Assets/Images/20-px-search-icon@3x.png";
import likeIcon from "../../Assets/Images/hearticon.png";

import Dropdown from "../../components/Dropdown";
import { dataShop } from "../Home/Shop/Thumnail";
import Pagination from "../../components/Pagination";

import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollToTop";

function Shop() {
  const navigate = useNavigate()
  const [categories] = useState(dataCategories);

  return (
    <main>
      {/* Banner  */}
      <div className="shop">
        <div className="banner">
          <img src={banner} alt="" />
        </div>

        {/* Shop Detail  */}
        <div className="shop-container">
          <div className="categories">
            {categories.map((item, index) => (
              <div key={index} className={`category ${item.active ? 'categoryActive' : ''}`}>
                <p>{item.tit}</p>
              </div>
            ))}
          </div>

          <div className="divide-shopDetails">
            <p>전체보기</p>
          </div>

          <div className="shop-detail">
            {/* Shop header  */}
            <div className="shop-detail-header">
              <div className="header-title">전체 상품 207</div>
              <div className="header-function">

                <div className="viewModes">
                  <div className="viewMode">
                    <img src={viewMode1} alt="" />
                  </div>
                  <div className="viewMode">
                    <img src={viewMode2} alt="" />
                  </div>
                </div>

                <div className="filter">
                  <Dropdown data={dataSelect} />
                </div>

                <div className="search">
                  <input type="text" placeholder="결과 내 검색" />
                  <div className="search-button">
                    <img src={searchButton} alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* shop detail  */}
            <div className="shop-items">
              {dataShop.map((item, index) => {
                return (
                  <div onClick={() => {
                    scrollToTop()
                    navigate(`/shop/${item.id}`)
                  }} key={index} className="item">
                    <div className="img">
                      <img src={item.img} alt="#" />
                    </div>
                    <h5>{item.title}</h5>
                    <div className="detail">
                      <p>{item.price}</p>
                      <div className="like">
                        <img src={likeIcon} alt="" /> <span>{item.like}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            
          </div>

          {/* Pagination  */}
          <Pagination />
        </div>
      </div>
    </main>
  );
}

export default Shop;

const dataCategories = [
  {
    tit: "All",
    active: true,
    router: "",
  },
  {
    tit: "wall deco",
    router: "",
  },
  {
    tit: "fashion",
    router: "",
  },
  {
    tit: "digital acc",
    router: "",
  },
  {
    tit: "living",
    router: "",
  },
  {
    tit: "habit",
    router: "",
  },
];

const dataSelect = [
    { value: '인기순', label: '인기순' },
    { value: '인기순', label: '인기순' },
    { value: '인기순', label: '인기순' },
    { value: '인기순', label: '인기순' },
  ]
