/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import CommonArticle from "../../../components/CommonArticle";
import "./styles.scss";
import Icon1 from "../../../Assets/Images/video@3x.png";
import Icon2 from "../../../Assets/Images/video-object-04.png";
import Icon3 from "../../../Assets/Images/video-object-01.png";
import Icon4 from "../../../Assets/Images/video-object-03.png";
import Icon5 from "../../../Assets/Images/video-object-08.png";
import Icon6 from "../../../Assets/Images/video-object-09.png";
import Icon7 from "../../../Assets/Images/video-object-05.png";
import Icon8 from "../../../Assets/Images/video-object-07.png";
import Icon9 from "../../../Assets/Images/video-object-06.png";
import Button from "../components/Button";

function Discover() {
  const src = `https://player.vimeo.com/video/776816497?h=a8067df734&autoplay=1&title=0&byline=0&portrait=0`;
  return (
    <div className="discover">
      <div className="article article--discover">
        <div className="discover__content">
          <CommonArticle
            title="what happened"
            detail="How to create mobile-optimized videos in minutes. Not a designer, 
                        every team makes a lot of videos Can be trimmed. Take the first"
            color="#111"
          />
          <div className="discover__button">
            <Button text="SEE MORE" />
          </div>
        </div>
      </div>

      <div className="discover__banner">
        <img src={Icon1} alt="" />
        <div className="discover__banner__iframe">
          <iframe
            src={src}
            className="iframe__video"
            allow="autoplay; fullscreen; picture-in-picture"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>

      <div className="discover__banner__hidden">
        <img src={Icon1} alt="" />
      </div>

      <div className="discover__right">
        <img src={Icon2} alt="" />
      </div>
      <div className="discover__bottom">
        <div className="discover__bottom--h">
          <img src={Icon4} alt="" />
        </div>
        <div className="discover__bottom--error">
          <img src={Icon3} alt="" />
        </div>
      </div>
      <div className="discover__left">
        <div className="discover__left--bottom">
          <img src={Icon6} alt="" />
        </div>
        <div className="discover__left--top">
          <img src={Icon5} alt="" />
        </div>
      </div>
      <div className="discover__top">
        <img src={Icon7} alt="" />
      </div>
      <div className="discover__heart">
        <div className="discover__heart--top">
          <img src={Icon8} alt="" />
        </div>
        <div className="discover__heart--bottom">
          <img src={Icon9} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Discover;
